<script setup lang="ts">
// vue
import { computed } from 'vue'

// nuxt
import { useRuntimeConfig } from '#app'

// types
import type { Nullable, OrganizationInfo } from '@revolutionprep/types'

// components
import Timezone from '../data-display/Timezone.vue'

/**
 * props
 * ==================================================================
 */
interface Props {
  absolute?: boolean
  app?: boolean
  company?: Nullable<OrganizationInfo>
  loggedIn?: boolean
  showTimezone?: boolean
  supportUrl?: Nullable<string>
}

const props = withDefaults(defineProps<Props>(), {
  absolute: true,
  app: true,
  company: null,
  loggedIn: false,
  showTimezone: true,
  supportUrl: null
})

/**
 * runtime config
 * ==================================================================
 */
const config = useRuntimeConfig()

/**
 * computed
 * ==================================================================
 */
const isStudentApp = computed(() => {
  return (config.public.appName as string) === 'student'
})

const supportLink = computed(() => {
  if (props.supportUrl) {
    return props.supportUrl
  }
  return !isStudentApp.value
    ? config.public.supportUrl as string
    : 'mailto:answers@revolutionprep.com'
})

const timezone = computed(() => {
  return new Date().toLocaleString(
    'en',
    { timeZoneName: 'short' }
  ).split(' ').pop()
})

const year = computed(() => {
  return new Date().getUTCFullYear()
})
</script>

<template>
  <v-footer
    :app="app"
    color="white"
    :absolute="absolute"
  >
    <v-container
      fluid
      class="px-1"
    >
      <v-row no-gutters>
        <v-col cols="12">
          <div class="d-flex justify-space-between align-center">
            <slot name="actions-start">
              <div
                v-if="company && config"
                class="footer__tagline"
              >
                <small>{{ company.tagLine }}.</small>
                <a
                  v-if="!isStudentApp"
                  :href="(config.public.supportUrl as string)"
                  class="text-secondary-lighten-1 d-flex"
                  target="_blank"
                  rel="noopener noreferrer"
                ><small>Give Feedback</small></a>
              </div>
            </slot>
            <div
              v-if="loggedIn && showTimezone"
              class="d-none d-sm-flex flex-column justify-center footer__timezone"
            >
              <Timezone
                v-if="timezone"
                :timezone="timezone"
              />
            </div>

            <slot name="actions-end">
              <div
                v-if="company && config"
                class="footer__copyright"
              >
                <small>
                  &copy; <client-only>{{ year }}</client-only> {{ company.name }}
                </small>
                <a
                  :href="supportLink"
                  class="text-secondary-lighten-1 d-flex justify-end"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <small>Report an issue</small>
                </a>
              </div>
            </slot>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <slot name="fab" />
  </v-footer>
</template>
